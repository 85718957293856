import React, {useEffect, useState, useContext} from 'react'
import InmueblesContext from '../contextos/InmueblesContext'
import { useAuth0 } from "@auth0/auth0-react";

                        
const DatosCotizacion = ({cantParqueos, cantBodegas, inmuebleSeleccionado, area, metrosCuadrados, total, reserva ,abono, firma, cuotas, contraEntrega, credito, parametroReserva, parametroFirma, parametroCredito, parametroCuotas, parametroEntrega, disponibilidad, nombreTabla, areaAzotea, areaPatio, areaInterna, areaBalcon, parqueo, bodega, metrosCuadradosInternos, precioBalcon, precioPatio, precioParqueo, precioBodega, precioAzotea, accesos}) => {

    const { inmuebleData, abrirDetalle, inputActivo , setInputActivo } = useContext(InmueblesContext)

    const [camposBloqueados, setCamposBloqueados] = useState(true)
    const {isAuthenticated, user } = useAuth0()
    
    useEffect(() => {

        console.log(accesos)

        const accesoAdmin = accesos.filter(usuario => usuario.values.correo === user.email && (usuario.values.acceso.name === "Admin" || usuario.values.acceso.name === "SuperAdmin"))
    
        accesoAdmin.length >= 1 ? setCamposBloqueados(false) : setCamposBloqueados(true)

    }, [user])


    return (
        <div>
            <table className='cotizador'>
            { nombreTabla === "TERRENOS SAN PATRICIO GARDENS" ?
            <>
                <tr>
                    <td>ÁREA:</td>
                    <td></td>
                    <td></td>
                    <td>{areaInterna} m²</td>
                </tr>
                <tr>
                    <td>VALOR POR (m²):</td>
                    <td></td>
                    <td></td>
                    <td>{parseInt(metrosCuadradosInternos).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                </tr>
                <tr>
                    <td>TOTAL:</td>
                    <td></td>
                    <td></td>
                    <td className='resalt'>{(areaInterna * metrosCuadradosInternos).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                </tr>
                <tr className='disponibilidad'>
                    <td>DISPONIBILIDAD:</td>
                    <td></td>
                    <td></td>
                    <td className={disponibilidad === "Disponible" ? "green" : disponibilidad === "Vendido" ? "rojo" : "gray"}>{disponibilidad}</td>
                </tr>
            </>
            : nombreTabla === "TOWN CENTER DEPARTAMENTOS" || nombreTabla === "TOWN CENTER OFICINAS" ? 
            <>
                <thead>
                    <tr>
                        <th></th>
                        <th>M²</th>
                        <th>U$D</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td>ÁREA INTERNA:</td>
                    <td>{areaInterna}</td>
                    <td>{parseInt(metrosCuadradosInternos).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    <td>{(areaInterna * metrosCuadradosInternos).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                </tr>
                <tr>
                    <td>ÁREA DEL BALCÓN:</td>
                    <td>{areaBalcon}</td>
                    <td>{parseInt(precioBalcon).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    <td>{(areaBalcon * precioBalcon).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                </tr>
                <tr>
                    <td>ÁREA DE AZOTEA:</td>
                    <td>{areaAzotea}</td>
                    <td>{parseInt(precioAzotea).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    <td>{(areaAzotea * precioAzotea).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                </tr>
                <tr>
                    <td>BODEGA:</td>
                    <td id='cant_bodegas'>{cantBodegas}</td>
                    <td>{parseInt(precioBodega).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    <td>{(cantBodegas * precioBodega).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                </tr>
                <tr>
                    <td>PARQUEO:</td>
                    <td id='cant_parqueos'>{cantParqueos} uni.</td>
                    <td>{parseInt(precioParqueo).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    <td>{(cantParqueos * precioParqueo).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                </tr>
                <tr>
                    <td>TOTAL:</td>
                    <td></td>
                    <td></td>
                    <td className='resalt'>{((areaInterna * metrosCuadradosInternos) + (areaBalcon * precioBalcon) + (areaAzotea * precioAzotea) + (cantParqueos * precioParqueo) + (cantBodegas * precioBodega)).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                </tr>
                <tr className='disponibilidad'>
                    <td>DISPONIBILIDAD:</td>
                    <td></td>
                    <td></td>
                    <td className={disponibilidad === "Disponible" ? "green" : disponibilidad === "Vendido" ? "rojo" : "gray"}>{disponibilidad}</td>
                </tr>
                </tbody>
            </>
            :
            <>
                <thead>
                    <tr>
                        <th></th>
                        <th>M²</th>
                        <th>US$</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td>ÁREA INTERNA:</td>
                    <td>{areaInterna}</td>
                    <td>{parseInt(metrosCuadradosInternos).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    <td>{(areaInterna * metrosCuadradosInternos).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                </tr>
                <tr>
                    <td>ÁREA DEL BALCÓN:</td>
                    <td>{areaBalcon}</td>
                    <td>{parseInt(precioBalcon).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    <td>{(areaBalcon * precioBalcon).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                </tr>
                <tr>
                    <td>ÁREA DE PATIO:</td>
                    <td>{areaPatio}</td>
                    <td>{parseInt(precioPatio).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    <td>{(areaPatio * precioPatio).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                </tr>
                <tr>
                    <td>ÁREA DE AZOTEA:</td>
                    <td>{areaAzotea}</td>
                    <td>{parseInt(precioAzotea).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    <td>{(areaAzotea * precioAzotea).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                </tr>
                <tr>
                    <td>BODEGA:</td>
                    <td id='cant_bodegas'>{cantBodegas}</td>
                    <td>{parseInt(precioBodega).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    <td>{(cantBodegas * precioBodega).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                </tr>
                <tr>
                    <td>PARQUEO:</td>
                    <td id='cant_parqueos'>{cantParqueos} uni.</td>
                    <td>{parseInt(precioParqueo).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    <td>{(cantParqueos * precioParqueo).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                </tr>
                <tr>
                    <td>TOTAL:</td>
                    <td></td>
                    <td></td>
                    <td className='resalt'>{((areaInterna * metrosCuadradosInternos) + (areaBalcon * precioBalcon) + (areaPatio * precioPatio) + (areaAzotea * precioAzotea) + (precioParqueo * cantParqueos) + (precioBodega * cantBodegas)).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                </tr>
                <tr className='disponibilidad'>
                    <td>DISPONIBILIDAD:</td>
                    <td></td>
                    <td></td>
                    <td className={disponibilidad === "Disponible" ? "green" : disponibilidad === "Vendido" ? "rojo" : "gray"}>{disponibilidad}</td>
                </tr>
                </tbody>
            </>
            }
            </table>
            <h2>FORMA DE PAGO</h2>
            <table>
                { nombreTabla === "TERRENOS SAN PATRICIO GARDENS" ?
                <tbody>
                    <tr>
                        <td>RESERVA:</td>
                        <td>{parametroReserva}%</td>
                        <td>US$</td>
                        <td>{(((areaInterna * metrosCuadradosInternos) * parametroReserva) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    </tr>
                    <tr>
                        <td>A LA FIRMA DEL CONTRATO:</td>
                        <td>{parametroFirma}%</td>
                        <td>US$</td>
                        <td>{(((areaInterna * metrosCuadradosInternos) * parametroFirma) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    </tr>
                    <tr>
                        <td>ABONO A GASTOS LEGALES:</td>
                        <td>{abono.toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>CRÉDITO DIRECTO:</td>
                        <td>{parametroCredito}%</td>
                        <td>US$</td>
                        <td>{((((areaInterna * metrosCuadradosInternos) * parametroCredito) / 100) + abono).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    </tr>
                    <tr>
                        <td>CUOTAS MENSUALES:</td>
                        <td>{parametroCuotas}</td>
                        <td>US$</td>
                        <td>{(((((areaInterna * metrosCuadradosInternos) * parametroCredito) / 100) + abono) / parametroCuotas).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    </tr>
                    <tr>
                        <td>CONTRA ENTREGA:</td>
                        <td>{parametroEntrega}%</td>
                        <td>US$</td>
                        <td>{(((areaInterna * metrosCuadradosInternos) * parametroEntrega) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    </tr>
                </tbody>
                : nombreTabla === "TOWN CENTER DEPARTAMENTOS" || nombreTabla === "TOWN CENTER OFICINAS" ?
                <tbody>
                    <tr>
                        <td>RESERVA:</td>
                        <td>{parametroReserva}%</td>
                        <td>US$</td>
                        <td>{((((areaInterna * metrosCuadradosInternos) + (areaBalcon * precioBalcon) + (areaAzotea * precioAzotea) + (cantParqueos * precioParqueo) + (precioBodega * cantBodegas)) * parametroReserva) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    </tr>
                    <tr>
                        <td>A LA FIRMA DEL CONTRATO:</td>
                        <td>{parametroFirma}%</td>
                        <td>US$</td>
                        <td>{((((areaInterna * metrosCuadradosInternos) + (areaBalcon * precioBalcon) + (areaAzotea * precioAzotea) + (cantParqueos * precioParqueo) + (precioBodega * cantBodegas)) * parametroFirma) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    </tr>
                    <tr>
                        <td>ABONO A GASTOS LEGALES:</td>
                        <td>{abono.toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>CRÉDITO DIRECTO:</td>
                        <td>{parametroCredito}%</td>
                        <td>US$</td>
                        <td>{(((((areaInterna * metrosCuadradosInternos) + (areaBalcon * precioBalcon) + (areaAzotea * precioAzotea) + (cantParqueos * precioParqueo) + (precioBodega * cantBodegas)) * parametroCredito) / 100) + abono).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    </tr>
                    <tr>
                        <td>CUOTAS MENSUALES:</td>
                        <td>{parametroCuotas}</td>
                        <td>US$</td>
                        <td>{((((((areaInterna * metrosCuadradosInternos) + (areaBalcon * precioBalcon) + (areaAzotea * precioAzotea) + (cantParqueos * precioParqueo) + (precioBodega * cantBodegas)) * parametroCredito) / 100) + abono) / parametroCuotas).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    </tr>
                    <tr>
                        <td>CONTRA ENTREGA:</td>
                        <td>{parametroEntrega}%</td>
                        <td>US$</td>
                        <td>{((((areaInterna * metrosCuadradosInternos) + (areaBalcon * precioBalcon) + (areaAzotea * precioAzotea) + (cantParqueos * precioParqueo) + (precioBodega * cantBodegas)) * parametroEntrega) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    </tr>
                </tbody>
                :
                <tbody>
                    <tr>
                        <td>RESERVA:</td>
                        <td>{parametroReserva}%</td>
                        <td>US$</td>
                        <td>{((((areaInterna * metrosCuadradosInternos) + (areaBalcon * precioBalcon) + (areaPatio * precioPatio) + (areaAzotea * precioAzotea) + (cantParqueos * precioParqueo) + (cantBodegas * precioBodega)) * parametroReserva) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    </tr>
                    <tr>
                        <td>A LA FIRMA DEL CONTRATO:</td>
                        <td>{parametroFirma}%</td>
                        <td>US$</td>
                        <td>{((((areaInterna * metrosCuadradosInternos) + (areaBalcon * precioBalcon) + (areaPatio * precioPatio) + (areaAzotea * precioAzotea) + (cantParqueos * precioParqueo) + (cantBodegas * precioBodega)) * parametroFirma) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    </tr>
                    <tr>
                        <td>ABONO A GASTOS LEGALES:</td>
                        <td>{abono.toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>CRÉDITO DIRECTO:</td>
                        <td>{parametroCredito}%</td>
                        <td>US$</td>
                        <td>{(((((areaInterna * metrosCuadradosInternos) + (areaBalcon * precioBalcon) + (areaPatio * precioPatio) + (areaAzotea * precioAzotea) + (cantParqueos * precioParqueo) + (precioBodega * cantBodegas)) * parametroCredito) / 100) + abono).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    </tr>
                    <tr>
                        <td>CUOTAS MENSUALES:</td>
                        <td>{parametroCuotas}</td>
                        <td>US$</td>
                        <td>{((((((areaInterna * metrosCuadradosInternos) + (areaBalcon * precioBalcon) + (areaPatio * precioPatio) + (areaAzotea * precioAzotea) + (cantParqueos * precioParqueo) + (precioBodega * cantBodegas)) * parametroCredito) / 100) + abono) / parametroCuotas).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    </tr>
                    <tr>
                        <td>CONTRA ENTREGA:</td>
                        <td>{parametroEntrega}%</td>
                        <td>US$</td>
                        <td>{((((areaInterna * metrosCuadradosInternos) + (areaBalcon * precioBalcon) + (areaPatio * precioPatio) + (areaAzotea * precioAzotea) + (cantParqueos * precioParqueo) + (precioBodega * cantBodegas)) * parametroEntrega) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                    </tr>
                </tbody>
                }
            </table>
            {/* Debo agregar la condicional del usuario admin para que puedan ellos SI cotizar inmuebles VENDIDOS o PENDIENTES */}

            
            {disponibilidad === "Disponible" || disponibilidad === "Pendiente" ?
            <table className='cotizarInmueble'>
                <tbody>
                    <tr>
                        <button onClick={abrirDetalle} value={inmuebleSeleccionado}>Cotizar inmueble</button>
                    </tr>
             </tbody>
            </table>
            :
            camposBloqueados === false ?
            <table className='cotizarInmueble'>
                <tbody>
                    <tr>
                        <button onClick={abrirDetalle} value={inmuebleSeleccionado}>Cotizar inmueble</button>
                    </tr>
             </tbody>
            </table>
            :
            <>

            </>
            }

        </div>
    )
}

export default DatosCotizacion
