import React, { useEffect, useState } from "react";
import InmueblesContext from "./InmueblesContext";
import inmueble from "../helpers/inmueble";

const InmueblesState = ({ children }) => {
  const url = "https://sanpatricio.appsployall.com/logs-app"; //https://inventario.sanpatricio.com.ec/logs-app //http://localhost:3300

  const [tablas, setTablas] = useState([]);
  const [cantTablas, setCantTablas] = useState(0);
  const [nombreTabla, setNombreTabla] = useState("TERRENOS SAN PATRICIO GARDENS");
  const [botonesTablas, setBotonesTablas] = useState([]);
  const [idTablas, setIdTablas] = useState("5387439");
  const [columnas, setColumnas] = useState([]);

  const [inmuebles, setInmuebles] = useState([]);
  const [cantInmuebles, setCantInmuebles] = useState(0);

  const [activar, setActivar] = useState(false);

  const [Backups, setBackups] = useState(false);

  const [idAccesos, setIdAccesos] = useState("");
  const [accesos, setAccesos] = useState([]);

  //const [inmuebles, setInmuebles] = useState([])
  const [inmuebleSeleccionado, setInmuebleSeleccionado] =
    useState("seleccionar");
  const [inmuebleActivo, setInmuebleActivo] = useState(false);
  const [formCotizacion, setFormCotizacion] = useState(false);
  const [inputActivo, setInputActivo] = useState(false);

  const [parametrosProyectos, setParametrosProyectos] = useState({});

  const [inmuebleData, setInmuebleData] = useState(
    inmueble(inmuebleSeleccionado, inmuebles, nombreTabla)
  );

  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const abrirDetalle = (e) => {
    console.log(e.target.value);
    setInmuebleActivo(!inmuebleActivo);
    setInmuebleSeleccionado(e.target.value);
    console.log("inmuebleSeleccionado", e.target.value);
  };

  const cerrarDetalle = (e) => {
    console.log(e.target.value);
    setInmuebleActivo(false);

    document
      .querySelectorAll(".inventario tr td input[type='checkbox']")
      .forEach((input) => {
        input.checked = false;
      });

    setInmuebleSeleccionado("seleccionar");
    console.log("Limpio los inmuebles y proyectos");
  };

  const realizarCotizacion = () => {
    console.log("Realizo Cotización");
    setFormCotizacion(true);
  };

  const handlerInmueble = (attr, value) => {
    console.log("cambia", attr, value);
    const obj = { [attr]: value };
    setInmuebleData((inmuebleData) => {
      return { ...inmuebleData, ...obj };
    });
  };

  const listaCuotas = async()=>{
    
  }

  useEffect(() => {
    let url_tablas = url + "/inmuebles";
    let requestTablas = {
      method: "GET",
      headers: {
        authorization: "Bearer " + process.env.REACT_APP_HUBSPOT_API_KEY,
      },
    };

    fetch(url_tablas, requestTablas)
      .then((response) => response.json())
      .then((result) => {
        setTablas(result.results);
        // console.log(console.log("ghghgh",tablas))
        setCantTablas(result.results.length);
        if (tablas.length !== 0) {
          console.log("ghghgh", tablas);
          const obtengoTablasAccesos = tablas.filter((TablasAccesos) =>
            TablasAccesos.label.includes("Accesos")
          );

          console.log(obtengoTablasAccesos[0].id);
          setIdAccesos(obtengoTablasAccesos[0].id);

          const tablasInmuebles = tablas.filter((TablasParametros) => TablasParametros.id == "5391733" || TablasParametros.id == "5412938" || TablasParametros.id == "5387440" || TablasParametros.id == "5387439" || TablasParametros.id == "5412967" || TablasParametros.id == "7066681");

          tablasInmuebles.sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          });

          console.log("tablasInmuebles", tablasInmuebles);
          setBotonesTablas(tablasInmuebles);
          setIdTablas(tablasInmuebles[0].id);
          setNombreTabla(tablasInmuebles[0].label.toUpperCase());

          tablasInmuebles[0].columns.forEach((element, i) => {
            if (
              tablasInmuebles[0].label.toUpperCase() ===
              "TERRENOS SAN PATRICIO GARDENS"
            ) {
              if (element.name == "n_dorm" || element.name == "parqueo") {
                console.log(element);
                tablasInmuebles[0].columns.splice(i, 1);
              }
            } else {
              if (
                element.name == "n_dorm" ||
                element.name == "parqueo" ||
                element.name == "precio_del_bien"
              ) {
                console.log(element);
                tablasInmuebles[0].columns.splice(i, 1);
              }
            }
          });

          setColumnas(tablasInmuebles[0].columns);
        }
      })
      .catch((error) => console.log("error", error));
  }, [cantTablas]);

  useEffect(() => {
    let url_rows = url + "/inmuebles/idtablas?idTablas=" + idTablas;
    let requestRows = {
      method: "GET",
      redirect: "follow",
      headers: {
        authorization: "Bearer " + process.env.REACT_APP_HUBSPOT_API_KEY,
      },
    };

    fetch(url_rows, requestRows)
      .then((response) => response.json())
      .then((result) => {
        console.log(result.results);

        setCantInmuebles(result.total);
        setInmuebles(result.results);

        console.log("inmuebles", result.results);
      })
      .catch((error) => console.log("error", error));
  }, [idTablas]);

  useEffect(() => {
    let url_rowsAccesos = url + "/inmuebles/accesos?idAccesos=" + idAccesos;
    let requestRowsAccesos = {
      method: "GET",
      redirect: "follow",
      headers: {
        authorization: "Bearer " + process.env.REACT_APP_HUBSPOT_API_KEY,
      },
    };

    fetch(url_rowsAccesos, requestRowsAccesos)
      .then((response) => response.json())
      .then((result) => {
        setAccesos(result.results);
      })
      .catch((error) => console.log("error", error));
  }, [idAccesos]);

  useEffect(() => {
    setInmuebleData(inmueble(inmuebleSeleccionado, inmuebles, nombreTabla));
  }, [inmuebleSeleccionado]);

  useEffect(() => {
    let url_tablas = url + "/parametros";
    let requestTablas = {
      method: "GET",
      headers: {
        authorization: "Bearer " + process.env.REACT_APP_HUBSPOT_API_KEY,
      },
    };

    fetch(url_tablas, requestTablas)
      .then((response) => response.json())
      .then((result) => {
        const proyectos = {};

        for (let item in result) {
            const { name, ...data } = result[item].values;
            proyectos[name] = data;
        }
        console.log("ESTOS SON TODOS LOS PROYECTOS Y SUS PARÁMETROS", proyectos);
        setParametrosProyectos(proyectos);
      }
    ).catch(e=>{
        console.log("NO SE PUDIERON CONSEGUIR LOS PROYECTOS Y SUS PARÁMETROS", e)
    })
}, []);

  const obtenerProyecto = (e) => {
    console.log(
      "Obtener data de: " + e.target.value + " | Tabla " + e.target.getAttribute("label")
    );
    setIdTablas(e.target.value);
    setNombreTabla(e.target.getAttribute("label").toUpperCase());
    let columnasProyecto = botonesTablas.filter(
      (columnasPorProyecto) => columnasPorProyecto.id === e.target.value
    );
    console.log(columnasProyecto);
    columnasProyecto[0].columns.forEach((element, i) => {
      if (
        e.target.getAttribute("label").toUpperCase() === "TERRENOS SAN PATRICIO GARDENS"
      ) {
        if (element.name == "n_dorm" || element.name == "parqueo") {
          console.log(element);
          columnasProyecto[0].columns.splice(i, 1);
        }
      } else {
        if (
          element.name == "n_dorm" ||
          element.name == "parqueo" ||
          element.name == "precio_del_bien"
        ) {
          console.log(element);
          columnasProyecto[0].columns.splice(i, 1);
        }
      }
    });
    setColumnas(columnasProyecto[0].columns);
    console.log(nombreTabla, e.target.getAttribute("label").toUpperCase());
  };

  const activarPestaña = () => {
    setActivar(!activar);
  };

  const mostrarBackups = () => {
    setBackups(!Backups);
  };

  return (
    <InmueblesContext.Provider
      value={{
        inmuebleData,
        handlerInmueble,
        abrirDetalle,
        inmuebleActivo,
        setInmuebleActivo,
        cerrarDetalle,
        inmuebleSeleccionado,
        setInmuebleSeleccionado,
        formCotizacion,
        setFormCotizacion,
        date,
        setNombreTabla,
        botonesTablas,
        cantTablas,
        activar,
        activarPestaña,
        cantInmuebles,
        inmuebles,
        columnas,
        nombreTabla,
        setInmuebles,
        accesos,
        obtenerProyecto,
        mostrarBackups,
        Backups,
        parametrosProyectos,
      }}
    >
      {children}
    </InmueblesContext.Provider>
  );
};

export default InmueblesState;
