import React, {useEffect, useState, useContext} from 'react'
import Tabla from './Tabla';
import Cotizador from './Cotizador';
import InmueblesContext from '../contextos/InmueblesContext';
import { useAuth0 } from "@auth0/auth0-react";

import Backups from './Backups';

const ContCotizador = () => {

    const {setNombreTabla, botonesTablas, cantTablas, activar, activarPestaña, cantInmuebles, inmuebles, columnas, nombreTabla, setInmuebles, accesos, obtenerProyecto, mostrarBackups} = useContext(InmueblesContext)

    const [buttonBackup, setButtonBackup] = useState(false)
    const {isAuthenticated, user } = useAuth0()

    useEffect(() => {

        console.log(accesos)

        const accesoAdmin = accesos.filter(usuario => usuario.values.correo === user.email && usuario.values.acceso.name === "SuperAdmin")
    
        accesoAdmin.length >= 1 ? setButtonBackup(true) : setButtonBackup(false)

    }, [user])

    return (
        <>
                <div className='btn_router'>
                    <button onClick={activarPestaña}>{activar === true ? "Ir al Inventario" : "Ir al Cotizador" }</button>
                    {buttonBackup? <button onClick={mostrarBackups}>Backups</button>: null }
                </div>    
                {activar === true ?             
                    <div className='cotizador'>
                        <div className='btn_cotizador'>    
                            <h2>COTIZACIÓN {nombreTabla}</h2>
                            { cantTablas > 0 ? (  
                                    botonesTablas.map(botones => {
                                        let textoBoton;
                                        if(botones.label == "Town Center Departamentos" ){
                                            textoBoton = "Town Center Dep. Torre 3"
                                        }else if(botones.label == "Town Center Oficinas" ){
                                            textoBoton = "Town Center Ofic. Torre 4"
                                        } else {
                                            textoBoton = botones.label;
                                        }
                                        return (
                                            <button key={botones.id} value={botones.id} label={botones.label} onClick={obtenerProyecto}>{textoBoton}</button>
                                        )
                                    })
                                )
                                : (
                                    <p className="advertencia">Cargando tablas.</p>
                                )
                            }           
                        </div>
                        <Cotizador cantInmuebles={cantInmuebles} inmuebles={inmuebles} nombreTabla={nombreTabla} setNombreTabla={setNombreTabla} accesos={accesos}/>
                    </div>     
                :            
                    <div className='inventario'>
                        <h2 className='title_proyectos'>INVENTARIO {nombreTabla}</h2>
                        <div className='btn_proyectos'>
                        { cantTablas > 0 ? (  
                                botonesTablas.map(botones => {
                                    let textoBoton;
                                    if(botones.label == "Town Center Departamentos" ){
                                        textoBoton = "Town Center Dep. Torre 3"
                                    }else if(botones.label == "Town Center Oficinas" ){
                                        textoBoton = "Town Center Ofic. Torre 4"
                                    } else {
                                        textoBoton = botones.label;
                                    }
                                    return (
                                        <button key={botones.id} value={botones.id} label={botones.label} onClick={obtenerProyecto}>{textoBoton}</button>
                                    )
                                })
                            )
                            : (
                                <p className="advertencia">Cargando tablas.</p>
                            )
                        } 
                        </div>
                        <Tabla cantInmuebles={cantInmuebles} inmuebles={inmuebles} columnas={columnas} nombreTabla={nombreTabla} setInmuebles={setInmuebles} accesos={accesos}/>
                    </div>   
                }
                <Backups/>
        </>
    )
}

export default ContCotizador
