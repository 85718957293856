import React, { useEffect, useState } from "react";

const Notificaciones = () => {

    const [notificaciones, setNotificaciones] = useState([])
    

    useEffect(()=>{
      let url_tablas =
      "https://inventario.sanpatricio.com.ec/logs-app/notificaciones" //"https://inventario.sanpatricio.com.ec/logs-app/notificaciones"
    let requestTablas = {
      method: "GET",
      redirect: "follow",
      headers:{
        authorization: 'Bearer '+process.env.REACT_APP_HUBSPOT_API_KEY
      }
    };

    fetch(url_tablas, requestTablas)
      .then((response) => response.json())
      .then((result) => {
        setNotificaciones(result.results);

        console.log("Notificaciones", result.results);
      });
    },[])
    
    const handleDelete = ((id) =>{

      setNotificaciones(notificaciones.filter((item) => item.id !== id))

    })


  return (
    <div className="cont-notificaciones">
      {notificaciones.map((notificacion) => {
        if(notificacion.values.mostrar.name == "Mostrar"){
        return (
          <div id={notificacion.id} className={"notificacion " + notificacion.values.color.name}>
            <p>
            {notificacion.values.texto}
            </p>
            <div className="Close" onClick={() =>handleDelete(notificacion.id)}>
              X
            </div>
          </div>
        );
        }
      })}
    </div>
  );
};

export default Notificaciones;
