import React, {useEffect, useState, useContext} from 'react'


//const { mostrarBackups } = useContext(InmueblesContext)

function Backups() {
  return (
    <div><button>Backups</button></div>
  )
}

export default Backups