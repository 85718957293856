import React, {useContext} from 'react'
import InmueblesState from './contextos/inmueblesState';
import Header from "./componentes/Header";
import { Auth0Provider } from "@auth0/auth0-react";
import Home from './pages/Home';
import Notificaciones from './componentes/Notificaciones'

function App() {
  

  return (
    <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIEN_ID}
    redirectUri={window.location.origin}
    >
      <Notificaciones/>
      <InmueblesState>
        <div className="App">
       
          <Header/>
          <main>
              <Home/>
          </main>
        </div>
      </InmueblesState>
    </Auth0Provider>
  );
}

export default App;
