import React, { useContext, useState, useEffect } from "react";
import InmueblesContext from "../contextos/InmueblesContext";
import logoTownCenter from "../images/SP-TownCenter.png";
import logoGardens from "../images/SP-Gardens.png";
import logoSkyView from "../images/SP-SkyViewTowers.png";
import logoAzul from "../images/logo_SP_azul.png";
import { useAuth0 } from "@auth0/auth0-react";
import inmueble from "../helpers/inmueble";

const CotizacionFinal = ({
  cantParqueos,
  cantBodegas,
  area,
  metrosCuadrados,
  total,
  reserva,
  abono,
  firma,
  cuotas,
  contraEntrega,
  credito,
  parametroReserva,
  parametroFirma,
  parametroCredito,
  parametroCuotas,
  parametroEntrega,
  disponibilidad,
  areaAzotea,
  areaPatio,
  areaInterna,
  areaBalcon,
  parqueo,
  bodega,
  metrosCuadradosInternos,
  precioBalcon,
  precioPatio,
  precioParqueo,
  precioBodega,
  precioAzotea,
  personalizado,
}) => {
  const { isAuthenticated, user } = useAuth0();

  const {
    inmuebleData,
    inmuebles,
    nombreTabla,
    abrirDetalle,
    inmuebleActivo,
    setInmuebleActivo,
    cerrarDetalle,
    inmuebleSeleccionado,
    date,
    setInmuebleSeleccionado,
    formCotizacion,
    setFormCotizacion,
    accesos
  } = useContext(InmueblesContext);

  const accesoAdmin = accesos.filter(usuario => usuario.values.correo === user.email)

  const userTelefono = accesoAdmin[0].values.telefono?accesoAdmin[0].values.telefono:0;

  console.log("userTelefono: " +userTelefono )

  const [detalleInmuebleSeleccionado, setDetalleInmuebleSeleccionado] =
    useState([]);

  const [abonoGastosLegales, setAbonoGastosLegales] = useState(3000);

  const [logoProyecto, setLogoProyecto] = useState("");

  useEffect(() => {
    if (nombreTabla === "TERRENOS SAN PATRICIO GARDENS") {
      setLogoProyecto(logoGardens);
    } else if (
      nombreTabla === "TOWN CENTER DEPARTAMENTOS" ||
      nombreTabla === "TOWN CENTER OFICINAS" ||
      nombreTabla === "TOWN CENTER OFICINAS T1"
    ) {
      setLogoProyecto(logoTownCenter);
    } else {
      setLogoProyecto(logoSkyView);
    }
  }, [nombreTabla]);

  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [cedula, setCedula] = useState(0);
  const [ciudad, setCiudad] = useState("");
  const [proyecto, setProyecto] = useState("");
  const [inmuebleCotizado, setInmuebleCotizado] = useState("");

  useEffect(() => {
    let filtroInmueble = inmuebles.filter(
      (inmueble) => inmueble.id === inmuebleSeleccionado
    );
    setDetalleInmuebleSeleccionado(filtroInmueble);
    //console.log(inmuebleCotizado)
    //console.log(proyecto)
  }, [inmuebleSeleccionado]);

  window.addEventListener("message", (event) => {
    if (
      event.data.type === "hsFormCallback" &&
      event.data.eventName === "onFormReady"
    ) {
      let nombreInput = document.querySelector(".hs-input[name='firstname']");
      setNombre(nombreInput.value);
      nombreInput.addEventListener("change", (event) => {
        console.log(nombreInput.value);
        setNombre(nombreInput.value);
      });
      let apellidoInput = document.querySelector(".hs-input[name='lastname']");
      setApellido(apellidoInput.value);
      apellidoInput.addEventListener("change", (event) => {
        console.log(apellidoInput.value);
        setApellido(apellidoInput.value);
      });
      let emailInput = document.querySelector(".hs-input[name='email']");
      setEmail(emailInput.value);
      emailInput.addEventListener("change", (event) => {
        console.log(emailInput.value);
        setEmail(emailInput.value);
      });
      let ciudadInput = document.querySelector(".hs-input[name='ciudad_']");
      setCiudad(ciudadInput.value);
      ciudadInput.addEventListener("change", (event) => {
        console.log(ciudadInput.value);
        setCiudad(ciudadInput.value);
      });
      let telefonoInput = document.querySelector(".hs-input[name='phone']");
      setTelefono(telefonoInput.value);
      telefonoInput.addEventListener("change", (event) => {
        console.log(telefonoInput.value);
        setTelefono(telefonoInput.value);
      });

      let proyectoSelect = document.querySelector(".hs-input[name='proyecto']");
      let torre1SkyView = document.querySelector(
        ".hs-input[name='nomenclatura_torre_1']"
      );
      let torre2SkyView = document.querySelector(
        ".hs-input[name='nomenclatura_torre_2']"
      );
      let townCenterDpto = document.querySelector(
        ".hs-input[name='torre_3___nomenclatura_']"
      );
      let townCenterOfic = document.querySelector(
        ".hs-input[name='torre_4___nomenclatura_']"
      );
      let terreno = document.querySelector(
        ".hs-input[name='s__p___gardens___nomenclatura']"
      );

      let usuario_inventario = document.querySelector(
        ".hs-input[name='usuario_inventario']"
      );

      proyectoSelect.value = "";
      torre1SkyView.value = "";
      torre2SkyView.value = "";
      townCenterDpto.value = "";
      townCenterOfic.value = "";
      terreno.value = "";
      //terreno.checked = false
      usuario_inventario = "";

      // Relleno inputs

      switch (nombreTabla) {
        case "TERRENOS SAN PATRICIO GARDENS":
          inmuebleData.inmueblecotizado =
            "LOTE " + detalleInmuebleSeleccionado[0].values.lote;
          break;
        case "TOWN CENTER DEPARTAMENTOS":
          inmuebleData.inmueblecotizado =
            "TOWN CENTER TORRE 3 - DEPARTAMENTO N°: " +
            detalleInmuebleSeleccionado[0].values.inmueble;
          break;
        case "TOWN CENTER OFICINAS":
          inmuebleData.inmueblecotizado =
            "TOWN CENTER TORRE 4 - OFICINAS N°: " +
            detalleInmuebleSeleccionado[0].values.inmueble;
          break;
        case "SKY VIEW TORRE 1":
          inmuebleData.inmueblecotizado =
            "TORRE 1 - DEPTO. " +
            detalleInmuebleSeleccionado[0].values.inmueble;
          break;
        case "TORRE 1 - DEPTO. ":
          inmuebleData.inmueblecotizado =
            "TORRE 1 - DEPTO. " +
            detalleInmuebleSeleccionado[0].values.inmueble;
          break;
        case "TORRE 2 - DEPTO. ":
          inmuebleData.inmueblecotizado =
            "TORRE 2 - DEPTO. " +
            detalleInmuebleSeleccionado[0].values.inmueble;
          break;
        case "TOWN CENTER OFICINAS T1":
          inmuebleData.inmueblecotizado =
            "TORRE 1 - OFICINA. " +
            detalleInmuebleSeleccionado[0].values.inmueble;
          break;
      }

      console.log("inmueblecotizado!!!", inmuebleData.inmueblecotizado);

      inmuebleData.area = areaInterna;
      inmuebleData.areaprecio = metrosCuadradosInternos;
      inmuebleData.disponibilidad = disponibilidad;
      inmuebleData.areaazotea = areaAzotea;
      inmuebleData.precioazotea = precioAzotea;
      inmuebleData.areabalcon = areaBalcon;
      inmuebleData.preciobalcon = precioBalcon;
      inmuebleData.parqueo = cantParqueos;
      inmuebleData.areapatio = areaPatio;
      inmuebleData.preciopatio = precioPatio;
      inmuebleData.areabodega = cantBodegas;
      inmuebleData.preciobodega = precioBodega;

      inmuebleData.valorarea = inmuebleData.area * inmuebleData.areaprecio;
      inmuebleData.azotea = inmuebleData.areaazotea * inmuebleData.precioazotea;
      inmuebleData.bodega = inmuebleData.areabodega * inmuebleData.preciobodega;
      inmuebleData.balcon = inmuebleData.areabalcon * inmuebleData.preciobalcon;
      inmuebleData.patio = inmuebleData.areapatio * inmuebleData.preciopatio;
      inmuebleData.cantparqueo = parseFloat(cantParqueos);
      inmuebleData.parqueo = parseFloat(
        inmuebleData.cantparqueo * precioParqueo
      );
      inmuebleData.areabodega = parseFloat(cantBodegas);
      inmuebleData.preciobodega = parseFloat(precioBodega);
      inmuebleData.preciototal = parseFloat(
        inmuebleData.area * inmuebleData.areaprecio +
          inmuebleData.areabalcon * inmuebleData.preciobalcon +
          inmuebleData.areapatio * inmuebleData.preciopatio +
          inmuebleData.areaazotea * inmuebleData.precioazotea +
          inmuebleData.cantparqueo * precioParqueo +
          inmuebleData.areabodega * inmuebleData.preciobodega
      );

      inmuebleData.reserva = parseFloat(
        (inmuebleData.preciototal * parametroReserva) / 100
      );
      inmuebleData.cuotas = parseFloat(
        ((inmuebleData.preciototal * parametroCredito) / 100 + abono) /
          parametroCuotas
      );
      inmuebleData.abono = parseFloat(abono);
      inmuebleData.credito = parseFloat(
        (inmuebleData.preciototal * parametroCredito) / 100 + abono
      );
      inmuebleData.contraentrega = parseFloat(
        (inmuebleData.preciototal * parametroEntrega) / 100
      );
      inmuebleData.firmacontrato = parseFloat(
        (inmuebleData.preciototal * parametroFirma) / 100
      );

      inmuebleData.porcentaje_reserva = parseFloat(parametroReserva);
      inmuebleData.porcentaje_firma = parseFloat(parametroFirma);
      inmuebleData.porcentaje_credito = parseFloat(parametroCredito);
      inmuebleData.cantidad_de_cuotas = parseFloat(parametroCuotas);
      inmuebleData.porcentaje_contra_entrega = parseFloat(parametroEntrega);

      inmuebleData.usuario_inventario = user.name;

      console.log("Data inmueble", inmuebleData);

      Object.entries(inmuebleData).forEach(([key, data]) => {
        try {
          console.log("props", key, data);
          document.querySelector(`.hs-input[name='${key}']`).value = data;
          console.log(
            document.querySelector(`.hs-input[name='${key}']`),
            document.querySelector(`.hs-input[name='${key}']`).value
          );
        } catch (e) {
          console.error(`fallo al agregar el valor de ${key}`);
        }
      });

      if (nombreTabla === "SKY VIEW TORRE 1") {
        proyectoSelect.value = "Sky View - Torre 1";
        torre1SkyView.value = detalleInmuebleSeleccionado[0].values.inmueble;

        torre2SkyView.value = "";
        townCenterDpto.value = "";
        townCenterOfic.value = "";
        terreno.value = "";
        setInmuebleCotizado(detalleInmuebleSeleccionado[0].values.inmueble);
        console.log(detalleInmuebleSeleccionado[0].values.inmueble);
      } else if (nombreTabla === "SKY VIEW TORRE 2") {
        proyectoSelect.value = "Sky View - Torre 2";
        torre2SkyView.value = detalleInmuebleSeleccionado[0].values.inmueble;

        torre1SkyView.value = "";
        townCenterDpto.value = "";
        townCenterOfic.value = "";
        terreno.value = "";
        setInmuebleCotizado(detalleInmuebleSeleccionado[0].values.inmueble);
        console.log(detalleInmuebleSeleccionado[0].values.inmueble);
      } else if (nombreTabla === "TOWN CENTER DEPARTAMENTOS") {
        proyectoSelect.value = "Town Center - Torre 3";
        townCenterDpto.value = detalleInmuebleSeleccionado[0].values.inmueble;

        torre1SkyView.value = "";
        torre2SkyView.value = "";
        townCenterOfic.value = "";
        terreno.value = "";
        setInmuebleCotizado(detalleInmuebleSeleccionado[0].values.inmueble);
        //console.log(detalleInmuebleSeleccionado[0].values.inmueble);
      } else if (nombreTabla === "TOWN CENTER OFICINAS" ) {
        proyectoSelect.value = "Town Center - Torre 4";
        townCenterOfic.value = detalleInmuebleSeleccionado[0].values.inmueble;

        torre1SkyView.value = "";
        torre2SkyView.value = "";
        townCenterDpto.value = "";
        terreno.value = "";
        setInmuebleCotizado(detalleInmuebleSeleccionado[0].values.inmueble);
        // console.log(detalleInmuebleSeleccionado[0].values.inmueble);
      } else if (nombreTabla === "TOWN CENTER OFICINAS T1" ) {
        proyectoSelect.value = "Town Center - Torre 1";
        townCenterOfic.value = detalleInmuebleSeleccionado[0].values.inmueble;

        torre1SkyView.value = "";
        torre2SkyView.value = "";
        townCenterDpto.value = "";
        terreno.value = "";
        setInmuebleCotizado(detalleInmuebleSeleccionado[0].values.inmueble);
        // console.log(detalleInmuebleSeleccionado[0].values.inmueble);
      } else {
        proyectoSelect.value = "San Patricio Gardens - Terrenos";
        terreno.value = detalleInmuebleSeleccionado[0].values.lote;
        //terreno.checked = true;

        torre1SkyView.value = "";
        torre2SkyView.value = "";
        townCenterDpto.value = "";
        townCenterOfic.value = "";
        setInmuebleCotizado(detalleInmuebleSeleccionado[0].values.lote);
        console.log(detalleInmuebleSeleccionado[0].values.lote);
      }

      setProyecto(proyectoSelect.value);

      document.querySelector(
        ".hs-input[name='cotizacion_personalizada']"
      ).value = personalizado;
    }
  });

  return (
    <div id="cotizacionImprimir">
      <header className="cotizacion-header">
        <img src={logoAzul} className="App-logo" alt="logo" width="150" />
        <img src={logoProyecto} className="App-logo" alt="logo" width="150" />
        <div className="datosAsesor">
          <p>{user.name}</p>
          <p>{user.email}</p>
          <p>{userTelefono}</p>
          <p>{date}</p>
        </div>
      </header>
      {detalleInmuebleSeleccionado.map((inmueble) => {
        return (
          <div key={inmueble.id} className="detalleCotizacion">
            <>
              <div>
                <h3>CLIENTE</h3>
                <table className="dato-cliente">
                  <thead>
                    <tr>
                      <th>NOMBRE</th>
                      <th>APELLIDO</th>
                      <th>CORREO</th>
                      <th>TELÉFONO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{nombre}</td>
                      <td>{apellido}</td>
                      <td>{email}</td>
                      <td>{telefono}</td>
                    </tr>
                  </tbody>
                </table>
                <h3>COTIZACIÓN {inmuebleData.inmueblecotizado} </h3>
                <table className="dato-inmueble">
                  {nombreTabla === "TERRENOS SAN PATRICIO GARDENS" ? (
                    <>
                      <tr>
                        <td>AREA:</td>
                        <td></td>
                        <td></td>
                        <td>{areaInterna}</td>
                      </tr>
                      <tr>
                        <td>VALOR POR (m²):</td>
                        <td></td>
                        <td></td>
                        <td>
                          {parseInt(metrosCuadradosInternos).toLocaleString(
                            "en",
                            {
                              style: "currency",
                              currency: "USD",
                            }
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>TOTAL:</td>
                        <td></td>
                        <td></td>
                        <td className="resalt">
                          {(
                            areaInterna * metrosCuadradosInternos
                          ).toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                      </tr>
                    </>
                  ) : nombreTabla === "TOWN CENTER DEPARTAMENTOS" ||
                    nombreTabla === "TOWN CENTER OFICINAS" ||
                    nombreTabla === "TOWN CENTER OFICINAS T1" ? (
                    <>
                      <thead>
                        <tr>
                          <th></th>
                          <th>M²</th>
                          <th>US$</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>ÁREA INTERNA:</td>
                          <td>{areaInterna}</td>
                          <td>
                            {parseInt(metrosCuadradosInternos).toLocaleString(
                              "en",
                              {
                                style: "currency",
                                currency: "USD",
                              }
                            )}
                          </td>
                          <td>
                            {(
                              areaInterna * metrosCuadradosInternos
                            ).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>ÁREA DEL BALCÓN:</td>
                          <td>{areaBalcon}</td>
                          <td>
                            {parseInt(precioBalcon).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                          <td>
                            {(areaBalcon * precioBalcon).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>ÁREA DE AZOTEA:</td>
                          <td>{areaAzotea}</td>
                          <td>
                            {parseInt(precioAzotea).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                          <td>
                            {(areaAzotea * precioAzotea).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>BODEGA:</td>
                          <td id="cant_bodegas">{cantBodegas}</td>
                          <td>
                            {parseInt(precioBodega).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                          <td>
                            {(cantBodegas * precioBodega).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>PARQUEO:</td>
                          <td id="cant_parqueos">{cantParqueos} uni.</td>
                          <td>
                            {parseInt(precioParqueo).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                          <td>
                            {(cantParqueos * precioParqueo).toLocaleString(
                              "en",
                              { style: "currency", currency: "USD" }
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>TOTAL:</td>
                          <td></td>
                          <td></td>
                          <td className="resalt">
                            {(
                              areaInterna * metrosCuadradosInternos +
                              areaBalcon * precioBalcon +
                              areaAzotea * precioAzotea +
                              cantParqueos * precioParqueo +
                              cantBodegas * precioBodega
                            ).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                        </tr>
                      </tbody>
                    </>
                  ) : (
                    <>
                      <thead>
                        <tr>
                          <th></th>
                          <th>M²</th>
                          <th>US$</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>ÁREA INTERNA:</td>
                          <td>{areaInterna}</td>
                          <td>
                            {parseInt(metrosCuadradosInternos).toLocaleString(
                              "en",
                              {
                                style: "currency",
                                currency: "USD",
                              }
                            )}
                          </td>
                          <td>
                            {(
                              areaInterna * metrosCuadradosInternos
                            ).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>ÁREA DEL BALCÓN:</td>
                          <td>{areaBalcon}</td>
                          <td>
                            {parseInt(precioBalcon).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                          <td>
                            {(areaBalcon * precioBalcon).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>ÁREA DE PATIO:</td>
                          <td>{areaPatio}</td>
                          <td>
                            {parseInt(precioPatio).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                          <td>
                            {(areaPatio * precioPatio).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>ÁREA DE AZOTEA:</td>
                          <td>{areaAzotea}</td>
                          <td>
                            {parseInt(precioAzotea).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                          <td>
                            {(areaAzotea * precioAzotea).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>BODEGA:</td>
                          <td id="cant_bodegas">{cantBodegas}</td>
                          <td>
                            {parseInt(precioBodega).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                          <td>
                            {(cantBodegas * precioBodega).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>PARQUEO:</td>
                          <td id="cant_parqueos">{cantParqueos} uni.</td>
                          <td>
                            {parseInt(precioParqueo).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                          <td>
                            {(cantParqueos * precioParqueo).toLocaleString(
                              "en",
                              { style: "currency", currency: "USD" }
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>TOTAL:</td>
                          <td></td>
                          <td></td>
                          <td className="resalt">
                            {(
                              areaInterna * metrosCuadradosInternos +
                              areaBalcon * precioBalcon +
                              areaPatio * precioPatio +
                              areaAzotea * precioAzotea +
                              precioParqueo * cantParqueos +
                              precioBodega * cantBodegas
                            ).toLocaleString("en", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                        </tr>
                      </tbody>
                    </>
                  )}
                </table>
                <h2>FORMA DE PAGO</h2>
                <table>
                  {nombreTabla === "TERRENOS SAN PATRICIO GARDENS" ? (
                    <tbody>
                      <tr>
                        <td>RESERVA:</td>
                        <td>{parametroReserva}%</td>
                        <td>US$</td>
                        <td>
                          {(
                            (areaInterna *
                              metrosCuadradosInternos *
                              parametroReserva) /
                            100
                          ).toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>A LA FIRMA DEL CONTRATO:</td>
                        <td>{parametroFirma}%</td>
                        <td>US$</td>
                        <td>
                          {(
                            (areaInterna *
                              metrosCuadradosInternos *
                              parametroFirma) /
                            100
                          ).toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>ABONO A GASTOS LEGALES:</td>
                        <td>
                          {abono.toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>CRÉDITO DIRECTO:</td>
                        <td>{parametroCredito}%</td>
                        <td>US$</td>
                        <td>
                          {(
                            (areaInterna *
                              metrosCuadradosInternos *
                              parametroCredito) /
                              100 +
                            abono
                          ).toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>CUOTAS MENSUALES:</td>
                        <td>{parametroCuotas}</td>
                        <td>US$</td>
                        <td>
                          {(
                            ((areaInterna *
                              metrosCuadradosInternos *
                              parametroCredito) /
                              100 +
                              abono) /
                            parametroCuotas
                          ).toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>CONTRA ENTREGA:</td>
                        <td>{parametroEntrega}%</td>
                        <td>US$</td>
                        <td>
                          {(
                            (areaInterna *
                              metrosCuadradosInternos *
                              parametroEntrega) /
                            100
                          ).toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                      </tr>
                    </tbody>
                  ) : nombreTabla === "TOWN CENTER DEPARTAMENTOS" ||
                    nombreTabla === "TOWN CENTER OFICINAS" || nombreTabla === "TOWN CENTER OFICINAS T1"  ? (
                    <tbody>
                      <tr>
                        <td>RESERVA:</td>
                        <td>{parametroReserva}%</td>
                        <td>US$</td>
                        <td>
                          {(
                            ((areaInterna * metrosCuadradosInternos +
                              areaBalcon * precioBalcon +
                              areaAzotea * precioAzotea +
                              cantParqueos * precioParqueo +
                              precioBodega * cantBodegas) *
                              parametroReserva) /
                            100
                          ).toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>A LA FIRMA DEL CONTRATO:</td>
                        <td>{parametroFirma}%</td>
                        <td>US$</td>
                        <td>
                          {(
                            ((areaInterna * metrosCuadradosInternos +
                              areaBalcon * precioBalcon +
                              areaAzotea * precioAzotea +
                              cantParqueos * precioParqueo +
                              precioBodega * cantBodegas) *
                              parametroFirma) /
                            100
                          ).toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>ABONO A GASTOS LEGALES:</td>
                        <td>
                          {abono.toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>CRÉDITO DIRECTO:</td>
                        <td>{parametroCredito}%</td>
                        <td>US$</td>
                        <td>
                          {(
                            ((areaInterna * metrosCuadradosInternos +
                              areaBalcon * precioBalcon +
                              areaAzotea * precioAzotea +
                              cantParqueos * precioParqueo +
                              precioBodega * cantBodegas) *
                              parametroCredito) /
                              100 +
                            abono
                          ).toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>CUOTAS MENSUALES:</td>
                        <td>{parametroCuotas}</td>
                        <td>US$</td>
                        <td>
                          {(
                            (((areaInterna * metrosCuadradosInternos +
                              areaBalcon * precioBalcon +
                              areaAzotea * precioAzotea +
                              cantParqueos * precioParqueo +
                              precioBodega * cantBodegas) *
                              parametroCredito) /
                              100 +
                              abono) /
                            parametroCuotas
                          ).toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>CONTRA ENTREGA:</td>
                        <td>{parametroEntrega}%</td>
                        <td>US$</td>
                        <td>
                          {(
                            ((areaInterna * metrosCuadradosInternos +
                              areaBalcon * precioBalcon +
                              areaAzotea * precioAzotea +
                              cantParqueos * precioParqueo +
                              precioBodega * cantBodegas) *
                              parametroEntrega) /
                            100
                          ).toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td>RESERVA:</td>
                        <td>{parametroReserva}%</td>
                        <td>US$</td>
                        <td>
                          {(
                            ((areaInterna * metrosCuadradosInternos +
                              areaBalcon * precioBalcon +
                              areaPatio * precioPatio +
                              areaAzotea * precioAzotea +
                              cantParqueos * precioParqueo +
                              cantBodegas * precioBodega) *
                              parametroReserva) /
                            100
                          ).toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>A LA FIRMA DEL CONTRATO:</td>
                        <td>{parametroFirma}%</td>
                        <td>US$</td>
                        <td>
                          {(
                            ((areaInterna * metrosCuadradosInternos +
                              areaBalcon * precioBalcon +
                              areaPatio * precioPatio +
                              areaAzotea * precioAzotea +
                              cantParqueos * precioParqueo +
                              cantBodegas * precioBodega) *
                              parametroFirma) /
                            100
                          ).toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>ABONO A GASTOS LEGALES:</td>
                        <td>
                          {abono.toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>CRÉDITO DIRECTO:</td>
                        <td>{parametroCredito}%</td>
                        <td>US$</td>
                        <td>
                          {(
                            ((areaInterna * metrosCuadradosInternos +
                              areaBalcon * precioBalcon +
                              areaPatio * precioPatio +
                              areaAzotea * precioAzotea +
                              cantParqueos * precioParqueo +
                              precioBodega * cantBodegas) *
                              parametroCredito) /
                              100 +
                            abono
                          ).toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>CUOTAS MENSUALES:</td>
                        <td>{parametroCuotas}</td>
                        <td>US$</td>
                        <td>
                          {(
                            (((areaInterna * metrosCuadradosInternos +
                              areaBalcon * precioBalcon +
                              areaPatio * precioPatio +
                              areaAzotea * precioAzotea +
                              cantParqueos * precioParqueo +
                              precioBodega * cantBodegas) *
                              parametroCredito) /
                              100 +
                              abono) /
                            parametroCuotas
                          ).toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>CONTRA ENTREGA:</td>
                        <td>{parametroEntrega}%</td>
                        <td>US$</td>
                        <td>
                          {(
                            ((areaInterna * metrosCuadradosInternos +
                              areaBalcon * precioBalcon +
                              areaPatio * precioPatio +
                              areaAzotea * precioAzotea +
                              cantParqueos * precioParqueo +
                              precioBodega * cantBodegas) *
                              parametroEntrega) /
                            100
                          ).toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </>

            <div className="disclaimer">
              <p>
                La información textual y gráfica aquí presentada es orientativa,
                pudiendo estar sujeta a modificaciones de carácter técnico
                derivadas del proyecto y su ejecución. Los precios pueden variar
                de acuerdo a la evolución de las condiciones del mercado durante
                la comercialización de los inmuebles. La empresa se reserva el
                derecho de efectuar dichas modificaciones. San Patricio Smart
                Living. Todos los derechos reservados.
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CotizacionFinal;
