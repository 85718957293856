import React, {useState, useEffect, useContext} from 'react'

const Columnas = ({columnas}) => {

    return (
        <>
            <tr>
                <th></th>
                { columnas.length > 3 ? (  
                    columnas.map((columna) =>{
                        return (
                            <th key={columna.id} id={columna.name}>{ columna.name == "banos" ? "Baños" : columna.label}</th>
                            )
                    })  )   : ( 
                        <th className="advertencia">Sin columnas para mostrar.</th>
                )}
            </tr>
        </>
    )
}

export default Columnas
