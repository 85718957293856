import React, {useEffect, useState, useContext} from 'react'
import logo from "../images/logo_SP_azul.png";
import { useAuth0 } from "@auth0/auth0-react";
import InmueblesContext from '../contextos/InmueblesContext';
import logoTownCenter from "../images/SP-TownCenter.png";
import logoGardens from "../images/SP-Gardens.png";
import logoSkyView from "../images/SP-SkyViewTowers.png";

const Header = () => {

  const {logout, loginWithRedirect, user, isAuthenticated } = useAuth0()

  const { setNombreTabla, nombreTabla } = useContext(InmueblesContext)

  const [logoProyecto, setLogoProyecto] = useState("");

  console.log("Estoy llamando a la tabla: " + nombreTabla);
  useEffect(() => {
    if (nombreTabla === "TERRENOS SAN PATRICIO GARDENS") {
      setLogoProyecto(logoGardens);
    } else if (
      nombreTabla === "TOWN CENTER DEPARTAMENTOS" ||
      nombreTabla === "TOWN CENTER OFICINAS" ||
      nombreTabla === "TOWN CENTER OFICINAS T1"
    ) {
      setLogoProyecto(logoTownCenter);
    } else {
      setLogoProyecto(logoSkyView);
    }
  }, [nombreTabla]);

  //console.log(user)

    return (
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" width="150"/>
          {isAuthenticated ? 
          <>
          <img src={logoProyecto} className="App-logo-proyecto" alt="logo-proyecto" width="150"/>
            <div className='login'>
              <div className='user_data'>
                <img src={user.picture} alt={user.name} />
                <div className='float'>
                  <h4>{user.name}</h4>
                  <p>{user.email}</p>
                  <button onClick={() => logout({ returnTo: window.location.origin })}>Cerrar Sesión</button>
                </div>
              </div>
            </div>
          </>
          :
          <div className='login'>
            <button className="acceder" onClick={() => loginWithRedirect()}>Iniciar Sesión</button>
          </div>
          }
        </header>
    )
}

export default Header
