import React, { useContext, useState, useEffect } from "react";
import InmueblesContext from "../contextos/InmueblesContext";

const DetalleDefault = ({ inmuebles, nombreTabla }) => {
  const {
    inmuebleData,
    abrirDetalle,
    inmuebleActivo,
    setInmuebleActivo,
    cerrarDetalle,
    inmuebleSeleccionado,
    setInmuebleSeleccionado,
    formCotizacion,
    setFormCotizacion,
    date,
    parametrosProyectos,
  } = useContext(InmueblesContext);

  const [detalleInmuebleSeleccionado, setDetalleInmuebleSeleccionado] =
    useState([]);

  const imprimirCotizacion = () => {
    console.log(date);
    window.print();
  };

  useEffect(() => {
    if (inmuebleActivo === true) {
      let filtroInmueble = inmuebles.filter(
        (inmueble) => inmueble.id === inmuebleSeleccionado
      );
      console.log(filtroInmueble);
      setDetalleInmuebleSeleccionado(filtroInmueble);
    }

    if (formCotizacion === true) {
      const script = document.createElement("script");
      script.src = "https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);

      const submitted = () => {
        const firstname = document.querySelector(
            "#hubspotForm input[name='firstname']"
          ).value,
          lastname = document.querySelector(
            "#hubspotForm input[name='lastname']"
          ).value,
          phone = document.querySelector(
            "#hubspotForm input[name='phone']"
          ).value,
          ciudad = document.querySelector(
            "#hubspotForm select[name='ciudad_']"
          ).value,
          message = document.querySelector(
            "#hubspotForm textarea[name='message']"
          ).value,
          email = document.querySelector(
            "#hubspotForm input[name='email']"
          ).value;

        console.log("email", email);

        const myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Bearer " + process.env.REACT_APP_HUBSPOT_API_KEY
        );
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          email: email,
          firstname: firstname,
          lastname: lastname,
          phone: phone,
          ciudad: ciudad,
          message: message,
          data: inmuebleData,
        });
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch("https://inventario.sanpatricio.com.ec/logs-app", requestOptions)
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.log("error", error));
        };

      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: "20027847",
            formId: "3767a9b7-5f4a-4a72-9723-005c6b573c48",
            target: "#hubspotForm",
            inlineMessage:
              'Contacto creado correctamente. <br> <button id="imprimir" onclick="window.print()">Imprimir cotización</button>',
            onFormSubmit: submitted,
            submissionErrors:
              'El envio del formulario fallo. Porfavor imprime y descarga la cotización. <br> <button id="imprimir" onclick="window.print()">Imprimir cotización</button>',
          });
        }

        console.log("formulario cargado");
      });
    }
  }, [inmuebleActivo, inmuebleSeleccionado, formCotizacion]);

  const [cuotas, setCuotas] = useState(12);
  const [abonoGastosLegales, setAbonoGastosLegales] = useState(3000);

  const realizarCotizacion = () => {
    console.log("Realizo Cotización");
    setFormCotizacion(true);
  };

  useEffect(() => {
    if (nombreTabla === "TERRENOS SAN PATRICIO GARDENS") {
      setCuotas(parametrosProyectos[nombreTabla].cuotas);
      setAbonoGastosLegales(3000);
    } else if (nombreTabla === "TOWN CENTER DEPARTAMENTOS" ||
    nombreTabla === "TOWN CENTER OFICINAS" ||
    nombreTabla === "TOWN CENTER OFICINAS T1") {
      setCuotas(parametrosProyectos[nombreTabla].cuotas);
      setAbonoGastosLegales(5000);
    } else {
      setCuotas(parametrosProyectos[nombreTabla].cuotas);
      setAbonoGastosLegales(5000);
    }
  }, [nombreTabla]);

  return (
    <aside
      className={inmuebleActivo === true ? "mostrar_detalle" : "cerrar_detalle"}
    >
      <div>
        {formCotizacion === false ? (
          <div key={inmuebleData.id}>
            {nombreTabla === "TERRENOS SAN PATRICIO GARDENS" ? (
              <>
                <header>
                  <h3>{inmuebleData.inmueblecotizado}</h3>
                </header>
                <div>
                  <p
                    className={
                      inmuebleData.disponibilidad === "Disponible"
                        ? "green"
                        : inmuebleData.disponibilidad === "Vendido"
                        ? "rojo"
                        : "gray"
                    }
                  >
                    {inmuebleData.disponibilidad}
                  </p>

                  <p>Área: {inmuebleData.area.toFixed(2)}m²</p>
                  <p>
                    Precio:{" "}
                    {inmuebleData.areaprecio.toLocaleString("en", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                  <p>
                    Total:{" "}
                    {inmuebleData.preciototal.toLocaleString("en", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                  <p>
                    Reserva:{" "}
                    {(inmuebleData.preciototal / 100).toLocaleString("en", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                  <p>
                    Cuotas mensuales:{" "}
                    {(
                      inmuebleData.preciototal / parametrosProyectos[nombreTabla].cuotas
                    ).toLocaleString("en", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                  <p>
                    <button
                      className="realizarCotizacion"
                      onClick={realizarCotizacion}
                    >
                      Realizar cotización
                    </button>
                  </p>
                </div>
              </>
            ) : nombreTabla === "TOWN CENTER DEPARTAMENTOS" ||
              nombreTabla === "TOWN CENTER OFICINAS" ? (
              <>
                <h3>{inmuebleData.inmueblecotizado} </h3>
                <div>
                  <p
                    className={
                      inmuebleData.disponibilidad === "Disponible"
                        ? "green"
                        : inmuebleData.disponibilidad === "Vendido"
                        ? "rojo"
                        : "gray"
                    }
                  >
                    {inmuebleData.disponibilidad}
                  </p>
                  <p>Área: {inmuebleData.area.toFixed(2)}m²</p>
                  <p>
                    Precio:{" "}
                    {inmuebleData.areaprecio.toLocaleString("en", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                  <p>
                    Total:{" "}
                    {inmuebleData.preciototal.toLocaleString("en", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                  <p>
                    Reserva:{" "}
                    {(inmuebleData.preciototal / 100).toLocaleString("en", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                  <p>
                    Cuotas mensuales:{" "}
                    {(
                      inmuebleData.preciototal / parametrosProyectos[nombreTabla].cuotas
                    ).toLocaleString("en", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                  <p>
                    <button
                      className="realizarCotizacion"
                      onClick={realizarCotizacion}
                    >
                      Realizar cotización
                    </button>
                  </p>
                </div>
              </>
            ) : (
              <>
                <h3>{inmuebleData.inmueblecotizado} </h3>
                <div>
                  <p
                    className={
                      inmuebleData.disponibilidad === "Disponible"
                        ? "green"
                        : inmuebleData.disponibilidad === "Vendido"
                        ? "rojo"
                        : "gray"
                    }
                  >
                    {inmuebleData.disponibilidad}
                  </p>
                  <p>Área: {inmuebleData.area.toFixed(2)}m²</p>
                  <p>
                    Precio:{" "}
                    {inmuebleData.areaprecio.toLocaleString("en", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                  <p>
                    Total:{" "}
                    {inmuebleData.preciototal.toLocaleString("en", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                  <p>
                    Reserva:{" "}
                    {(inmuebleData.preciototal / 100).toLocaleString("en", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                  <p>
                    Cuotas mensuales:{" "}
                    {(
                      inmuebleData.preciototal / parametrosProyectos[nombreTabla].cuotas
                    ).toLocaleString("en", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                  <p>
                    <button
                      className="realizarCotizacion"
                      onClick={realizarCotizacion}
                    >
                      Realizar cotización
                    </button>
                  </p>
                </div>
              </>
            )}
          </div>
        ) : (
          <div>
            <header>
              <h3>COTIZACIÓN</h3>
            </header>
          </div>
        )}
        {formCotizacion === false ? (
          <>
            <footer>
              <a
                href="https://app.hubspot.com/contacts/20027847/objects/0-1/views/all/list"
                target="_blank"
              >
                Contactos Hubspot
              </a>
              <a
                 href="https://app.hubspot.com/contacts/20027847/objects/0-3/views/all/list"
                target="_blank"
              >
                Negocios Hubspot
              </a>
            </footer>
            <button className="cerrar" onClick={cerrarDetalle}>
              X
            </button>
          </>
        ) : (
          <>
            <div id="hubspotForm"></div>
            <footer>
              <a
                 href="https://app.hubspot.com/contacts/20027847/objects/0-1/views/all/list"
                target="_blank"
              >
                Contactos Hubspot
              </a>
              <a
                  href="https://app.hubspot.com/contacts/20027847/objects/0-3/views/all/list"
                target="_blank"
              >
                Negocios Hubspot
              </a>
            </footer>
            <button className="cerrar" onClick={() => setFormCotizacion(false)}>
              &#60;
            </button>
          </>
        )}
      </div>
    </aside>
  );
};

export default DetalleDefault;