import React, {useContext, useState, useEffect} from 'react'
import InmueblesContext from '../contextos/InmueblesContext'
import logoTownCenter from "../images/SP-TownCenter.png"
import logoGardens from "../images/SP-Gardens.png"
import logoSkyView from "../images/SP-SkyViewTowers.png"
import logoAzul from "../images/logo_SP_azul.png";
import { useAuth0 } from "@auth0/auth0-react";


const CotizacionFinalTabla = () => {
    
    const {isAuthenticated, user } = useAuth0()
    
    const {inmuebleData, inmuebles, accesos, nombreTabla, abrirDetalle, inmuebleActivo, setInmuebleActivo, cerrarDetalle, inmuebleSeleccionado, date, setInmuebleSeleccionado, formCotizacion , setFormCotizacion, parametrosProyectos } = useContext(InmueblesContext)

    const [logoProyecto, setLogoProyecto] = useState("")

    const accesoAdmin = accesos.filter(usuario => usuario.values.correo === user.email)

    const userTelefono = accesoAdmin[0].values.telefono?accesoAdmin[0].values.telefono:0;
  
    console.log("userTelefono: " +userTelefono )
    
    const [nombre, setNombre] = useState("")
    const [apellido, setApellido] = useState("")
    const [email, setEmail] = useState("")
    const [telefono, setTelefono] = useState("")
    const [cedula, setCedula] = useState(0)
    const [ciudad, setCiudad] = useState("")
    const [proyecto, setProyecto] = useState("")
    const [inmuebleCotizado, setInmuebleCotizado] = useState("")
    

    window.addEventListener("message", (event) => {
      if (
        event.data.type === "hsFormCallback" &&
        event.data.eventName === "onFormReady"
      ) {
        let nombreInput = document.querySelector(".hs-input[name='firstname']");
      setNombre(nombreInput.value);
      nombreInput.addEventListener("change", (event) => {
        console.log(nombreInput.value);
        setNombre(nombreInput.value);
      });
      let apellidoInput = document.querySelector(".hs-input[name='lastname']");
      setApellido(apellidoInput.value);
      apellidoInput.addEventListener("change", (event) => {
        console.log(apellidoInput.value);
        setApellido(apellidoInput.value);
      });
      let emailInput = document.querySelector(".hs-input[name='email']");
      setEmail(emailInput.value);
      emailInput.addEventListener("change", (event) => {
        console.log(emailInput.value);
        setEmail(emailInput.value);
      });

      let ciudadInput = document.querySelector(".hs-input[name='ciudad_']");
      setCiudad(ciudadInput.value);
      ciudadInput.addEventListener("change", (event) => {
        console.log(ciudadInput.value);
        setCiudad(ciudadInput.value);
      });
      
      let telefonoInput = document.querySelector(".hs-input[name='phone']");
      setTelefono(telefonoInput.value);
      telefonoInput.addEventListener("change", (event) => {
        console.log(telefonoInput.value);
        setTelefono(telefonoInput.value);
      });


    Object.entries(inmuebleData).forEach(([key, data])=>{
      try{
        console.log("props", key, data)
        document.querySelector(`.hs-input[name='${key}']`).value = data
        console.log(document.querySelector(`.hs-input[name='${key}']`), document.querySelector(`.hs-input[name='${key}']`).value)
      } catch(e){
        console.error(`fallo al agregar el valor de ${key}`)
      }
      
    })

    console.log("Form Listo", inmuebleData)
  }
  })


    return (
        <div id="cotizacionImprimir">
            <header className="cotizacion-header">
                <img src={logoAzul} className="App-logo" alt="logo" width="150"/>
                <img src={logoProyecto} className="App-logo" alt="logo" width="150"/>
                <div className='datosAsesor'>
                    <p>{user.name}</p>
                    <p>{user.email}</p>
                    <p>{userTelefono?userTelefono:"099 933 1700"}</p>
                    <p>{date}</p>
                </div>
            </header>

             <div key={inmuebleSeleccionado} className="detalleCotizacion">
                          <>
                            <div>
                              <h3>CLIENTE</h3>
                              <table className="dato-cliente">
                                <thead>
                                  <tr>
                                    <th>NOMBRE</th>
                                    <th>APELLIDO</th>
                                    <th>CORREO</th>
                                    <th>TELÉFONO</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{nombre}</td>
                                    <td>{apellido}</td>
                                    <td>{email}</td>
                                    <td>{telefono}</td>
                                  </tr>
                                </tbody>
                              </table>
                              <h3>COTIZACIÓN {inmuebleData.inmueblecotizado} </h3>
                              <table className="dato-inmueble">
                                {nombreTabla === "TERRENOS SAN PATRICIO GARDENS" ? (
                                  <>
                                    <tr>
                                      <td>ÁREA:</td>
                                      <td></td>
                                      <td></td>
                                      <td>{inmuebleData.area.toLocaleString("en")}</td>
                                    </tr>
                                    <tr>
                                      <td>VALOR POR (m²):</td>
                                      <td></td>
                                      <td></td>
                                      <td>
                                        {inmuebleData.areaprecio.toLocaleString("en", {
                                          style: "currency",
                                          currency: "USD",
                                        })}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>TOTAL:</td>
                                      <td></td>
                                      <td></td>
                                      <td className="resalt">
                                        {inmuebleData.preciototal.toLocaleString("en", {
                                          style: "currency",
                                          currency: "USD",
                                        })}
                                      </td>
                                    </tr>
                                    
                                  </>
                                ) : nombreTabla === "TOWN CENTER DEPARTAMENTOS" ||
                                  nombreTabla === "TOWN CENTER OFICINAS" ? (
                                  <>
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th>M²</th>
                                        <th>US$</th>
                                        <th>Total</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>ÁREA INTERNA:</td>
                                        <td>{inmuebleData.area}</td>
                                        <td>
                                          {inmuebleData.areaprecio.toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                        <td>
                                          {(
                                            inmuebleData.area * inmuebleData.areaprecio
                                          ).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>ÁREA DEL BALCÓN:</td>
                                        <td>{inmuebleData.areabalcon}</td>
                                        <td>
                                          {inmuebleData.preciobalcon.toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                        <td>
                                          {(inmuebleData.areabalcon * inmuebleData.preciobalcon).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>ÁREA DE AZOTEA:</td>
                                        <td>{inmuebleData.areaazotea}</td>
                                        <td>
                                          {inmuebleData.precioazotea.toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                        <td>
                                          {(inmuebleData.areaazotea * inmuebleData.precioazotea).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>BODEGA:</td>
                                        <td id="cant_bodegas">{inmuebleData.bodega}</td>
                                        <td></td>
                                        <td>
                                          {(inmuebleData.bodega * inmuebleData.preciobodega).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>PARQUEO:</td>
                                        <td id="cant_parqueos">{inmuebleData.parqueo} uni.</td>
                                        <td></td>
                                        <td>
                                          {(inmuebleData.parqueo).toLocaleString(
                                            "en",
                                            { style: "currency", currency: "USD" }
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>TOTAL:</td>
                                        <td></td>
                                        <td></td>
                                        <td className="resalt">
                                          {(
                                            inmuebleData.preciototal
                                          ).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      
                                    </tbody>
                                  </>
                                ) : (
                                  <>
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th>M²</th>
                                        <th>US$</th>
                                        <th>Total</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>ÁREA INTERNA:</td>
                                        <td>{inmuebleData.area}</td>
                                        <td>
                                          {parseInt(
                                            inmuebleData.areaprecio
                                          ).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                        <td>
                                          {(
                                            inmuebleData.area * inmuebleData.areaprecio
                                          ).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>ÁREA DEL BALCÓN:</td>
                                        <td>{inmuebleData.areabalcon}</td>
                                        <td>
                                          {parseInt(inmuebleData.preciobalcon).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                        <td>
                                          {(inmuebleData.areabalcon * inmuebleData.preciobalcon).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>ÁREA DE PATIO:</td>
                                        <td>{inmuebleData.areapatio}</td>
                                        <td>
                                          {parseInt(inmuebleData.preciopatio).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                        <td>
                                          {(inmuebleData.areapatio * inmuebleData.preciopatio).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>ÁREA DE AZOTEA:</td>
                                        <td>{inmuebleData.areaazotea}</td>
                                        <td>
                                          {parseInt(inmuebleData.precioazotea).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                        <td>
                                          {(inmuebleData.areaazotea * inmuebleData.precioazotea).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>BODEGA:</td>
                                        <td id="cant_bodegas">{inmuebleData.bodega}</td>
                                        <td></td>
                                        <td>
                                          {(inmuebleData.bodega * inmuebleData.preciobodega).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>PARQUEO:</td>
                                        <td id="cant_parqueos">{inmuebleData.cantparqueo} uni.</td>
                                        <td></td>
                                        <td>
                                          {inmuebleData.parqueo }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>TOTAL:</td>
                                        <td></td>
                                        <td></td>
                                        <td className="resalt">
                                          {inmuebleData.preciototal.toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      
                                    </tbody>
                                  </>
                                )}
                              </table>
                          <h2>FORMA DE PAGO</h2>
                          <table>
                              { nombreTabla === "TERRENOS SAN PATRICIO GARDENS" ?
                              <tbody>
                                  <tr>
                                      <td>RESERVA:</td>
                                      <td>{inmuebleData.porcentaje_reserva}%</td>
                                      <td>US$</td>
                                      <td>{(((inmuebleData.area * inmuebleData.areaprecio) * inmuebleData.porcentaje_reserva) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>A LA FIRMA DEL CONTRATO:</td>
                                      <td>{inmuebleData.porcentaje_firma}%</td>
                                      <td>US$</td>
                                      <td>{(((inmuebleData.area * inmuebleData.areaprecio) * inmuebleData.porcentaje_firma) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>ABONO A GASTOS LEGALES:</td>
                                      <td></td>
                                      <td></td>
                                      <td>{inmuebleData.abono.toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>CRÉDITO DIRECTO:</td>
                                      <td>{inmuebleData.porcentaje_credito}%</td>
                                      <td>US$</td>
                                      <td>{((((inmuebleData.area * inmuebleData.areaprecio) * inmuebleData.porcentaje_credito) / 100) + inmuebleData.abono).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>CUOTAS MENSUALES:</td>
                                      <td>{parametrosProyectos[nombreTabla].cuotas}</td>
                                      <td>US$</td>
                                      <td>{(((((inmuebleData.area * 
                                      inmuebleData.areaprecio) * 
                                      inmuebleData.porcentaje_credito) / 100) + inmuebleData.abono) 
                                      / parametrosProyectos[nombreTabla].cuotas).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>CONTRA ENTREGA:</td>
                                      <td>{inmuebleData.porcentaje_contra_entrega}%</td>
                                      <td>US$</td>
                                      <td>{(((inmuebleData.area * inmuebleData.areaprecio) * inmuebleData.porcentaje_contra_entrega) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                              </tbody>
                              : nombreTabla === "TOWN CENTER DEPARTAMENTOS" || nombreTabla === "TOWN CENTER OFICINAS" ?
                              <tbody>
                                  <tr>
                                      <td>RESERVA:</td>
                                      <td>{inmuebleData.porcentaje_reserva}%</td>
                                      <td>US$</td>
                                      <td>{((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.preciobodega * inmuebleData.bodega)) * inmuebleData.porcentaje_reserva) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>A LA FIRMA DEL CONTRATO:</td>
                                      <td>{inmuebleData.porcentaje_firma}%</td>
                                      <td>US$</td>
                                      <td>{((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.preciobodega * inmuebleData.bodega)) * inmuebleData.porcentaje_firma) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>ABONO A GASTOS LEGALES:</td>
                                      <td></td>
                                      <td></td>
                                      <td>{inmuebleData.abono.toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>CRÉDITO DIRECTO:</td>
                                      <td>{inmuebleData.porcentaje_credito}%</td>
                                      <td>US$</td>
                                      <td>{(((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.preciobodega * inmuebleData.bodega)) * inmuebleData.porcentaje_credito) / 100) + inmuebleData.abono).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>CUOTAS MENSUALES:</td>
                                      <td>{parametrosProyectos[nombreTabla].cuotas}</td>
                                      <td>US$</td>
                                      <td>{((((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.preciobodega * inmuebleData.bodega)) * inmuebleData.porcentaje_credito) / 100) + inmuebleData.abono) / parametrosProyectos[nombreTabla].cuotas).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>CONTRA ENTREGA:</td>
                                      <td>{inmuebleData.porcentaje_contra_entrega}%</td>
                                      <td>US$</td>
                                      <td>{((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.preciobodega * inmuebleData.bodega)) * inmuebleData.porcentaje_contra_entrega) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                              </tbody>
                              :
                              <tbody>
                                  <tr>
                                      <td>RESERVA:</td>
                                      <td>{inmuebleData.porcentaje_reserva}%</td>
                                      <td>US$</td>
                                      <td>{((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areapatio * inmuebleData.preciopatio) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.bodega * inmuebleData.preciobodega)) * inmuebleData.porcentaje_reserva) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>A LA FIRMA DEL CONTRATO:</td>
                                      <td>{inmuebleData.porcentaje_firma}%</td>
                                      <td>US$</td>
                                      <td>{((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areapatio * inmuebleData.preciopatio) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.bodega * inmuebleData.preciobodega)) * inmuebleData.porcentaje_firma) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>ABONO A GASTOS LEGALES:</td>
                                      <td></td>
                                      <td></td>
                                      <td>{inmuebleData.abono.toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>CRÉDITO DIRECTO:</td>
                                      <td>{inmuebleData.porcentaje_credito}%</td>
                                      <td>US$</td>
                                      <td>{(((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areapatio * inmuebleData.preciopatio) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.preciobodega * inmuebleData.bodega)) * inmuebleData.porcentaje_credito) / 100) + inmuebleData.abono).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>CUOTAS MENSUALES:</td>
                                      <td>{parametrosProyectos[nombreTabla].cuotas}</td>
                                      <td>US$</td>
                                      <td>{((((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areapatio * inmuebleData.preciopatio) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.preciobodega * inmuebleData.bodega)) * inmuebleData.porcentaje_credito) / 100) + inmuebleData.abono) / parametrosProyectos[nombreTabla].cuotas).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>CONTRA ENTREGA:</td>
                                      <td>{inmuebleData.porcentaje_contra_entrega}%</td>
                                      <td>US$</td>
                                      <td>{((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areapatio * inmuebleData.preciopatio) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.preciobodega * inmuebleData.bodega)) * inmuebleData.porcentaje_contra_entrega) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                              </tbody>
                              }
                          </table>
                            </div>
                          </>
              
                          <div className="disclaimer">
                            <p>
                            La información textual y gráfica aquí presentada es orientativa, pudiendo estar sujeta a modificaciones de carácter técnico derivadas del proyecto y su ejecución. Los precios pueden variar de acuerdo a la
              evolución de las condiciones del mercado durante la comercialización de los inmuebles. La empresa se reserva el derecho de efectuar dichas modificaciones. San Patricio Smart Living. Todos los derechos reservados.
                            </p>
                          </div>
                        </div>
        </div>
    )
}

export default CotizacionFinalTabla
