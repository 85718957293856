import React, {useState, useEffect, useContext} from 'react'
import Filas from './Filas';
import Columnas from './Columnas';
import CotizacionFinalTabla from './InventarioTabla';

import DetalleDefault from './DetalleDefault';

import InmueblesContext from '../contextos/InmueblesContext'

const Tabla = () => {

    const { inmuebles, cantInmuebles, columnas, nombreTabla, setInmuebles } = useContext(InmueblesContext)

    const [inputBusqueda, setInputBusqueda] = useState("")
    const [inputDisponibles, setInputDisponibles] = useState("Todos")
    const [inmuebleFiltrado, setInmuebleFiltrado] = useState([]);
    
    const buscarDisponibles = (e) => {
        //console.log(e.target.value)
        setInputDisponibles(e.target.value)
    }
    
    useEffect(() => {
        console.log(inputDisponibles)
        setInmuebles(inmuebles)
        if (inputDisponibles != "Todos"){
            const inmueblesDisponibles = inmuebles.filter(inmueble => inmueble.values.disponibilidad.name === inputDisponibles)
            console.log(inmueblesDisponibles)
            //setInmuebles(inmueblesDisponibles)
            setInmuebleFiltrado(inmueblesDisponibles)
            console.log(inmuebles)
        } else {
            setInmuebleFiltrado(inmuebles)
        }
        
    }, [inputDisponibles])
    
    useEffect(() => {
        setInputDisponibles("Todos")
    },[nombreTabla])

    return (
        <>
            <table className='noBorder'>
                <tbody>
                    <tr>
                        <td>Filtrar inmuebles:</td>
                        <td>
                            <select name="disponibilidad" onChange={buscarDisponibles} value={inputDisponibles}>
                                <option>Todos</option>
                                <option>Disponible</option>
                                <option>En reserva</option>
                                <option>Pendiente</option>
                                <option>Bloqueado</option>
                                <option>Vendido</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className='container_tablas'>
                <table className={nombreTabla + ' lista_inventario'}>
                    <thead>
                        <Columnas columnas={columnas} inmuebles={inputDisponibles != "Todos" ? inmuebleFiltrado : inmuebles} nombreTabla={nombreTabla}/>
                    </thead>
                    <tbody>
                        <Filas cantInmuebles={cantInmuebles} inmuebles={inputDisponibles != "Todos" ? inmuebleFiltrado : inmuebles} nombreTabla={nombreTabla}/>
                    </tbody>
                </table>
                <DetalleDefault inmuebles={inmuebles} nombreTabla={nombreTabla}/>
                <CotizacionFinalTabla inmuebles={inmuebles} nombreTabla={nombreTabla}/>
            </div>
        </>
    )
}

export default Tabla
